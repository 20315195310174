import { GET_DASHBOARD_CRED_DATA } from "src/actions/type";

const siteCredReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DASHBOARD_CRED_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default siteCredReducer;
