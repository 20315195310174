import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { getDomoDashboardDetails } from "src/actions/dashboard";
import ReactHtmlParser from "react-html-parser";
import NoData from "src/components/Shared/NoData";
import Preloader from "src/components/Shared/Preloader";

const DashboardData = ({ user }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setSrcDoc("");
    setNoDashboard(true);
    setLoadingDashboard(true);

    const script = document.createElement("script");
    script.src = "https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp";
    script.async = true;
    script.id = "tailwindScript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [pathname]);

  const [srcDoc, setSrcDoc] = useState("");
  const [noDashboard, setNoDashboard] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  useEffect(() => {
    if (user?._id) {
      let dashboardId = pathname.split("/analytics/")[1];
      getDashboard(dashboardId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user?._id]);

  const getDashboard = async (dashboardId) => {
    let res = await getDomoDashboardDetails({ user_id: user?._id, id: dashboardId });
    setLoadingDashboard(false);
    if (res.status === 400) {
      setNoDashboard(true);
      return;
    }
    setNoDashboard(false);

    if (res.dashboard_type === "custom") {
      setSrcDoc(res.custom_html);
    } else {
      let { embedToken, embedUrl } = res;

      let htmlIframe = `<iframe id="iframe" title="dashboard-embed" src="" width="100%" height="100%" marginHeight="0" marginWidth="0" frameBorder="0" srcDoc='<html>
      <body>
        <form id="form" action="${embedUrl}" method="post">
          <input type="hidden" name="embedToken" value="${embedToken}" />
        </form>
      </body>
      <script>document.getElementById("form").submit();</script>
    </html>'></iframe>`;

      setSrcDoc(htmlIframe);
    }
  };

  return (
    <div id="pageContent" className="h-full w-full flex flex-col">
      <div className="h-full w-full">{!loadingDashboard && !noDashboard && srcDoc ? ReactHtmlParser(srcDoc) : <div className="w-full h-full flex justify-center mt-20">{loadingDashboard ? <Preloader /> : !loadingDashboard && noDashboard && <NoData />}</div>}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(DashboardData);
