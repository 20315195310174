import { Combobox, Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { getUser } from "src/actions/auth";
import { getUsers } from "src/actions/user";
import { baseUrl, noProfile } from "src/config/host";
import { classNames } from "src/helpers/classNames";

function RolePreview({ open = false, setOpen = () => {}, users, getUser, getUsers }) {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState({});

  const setPreviewUserId = async (id) => {
    localStorage.setItem("previewUserId", id);
    const ac = new AbortController();
    await getUser(ac.signal);
    navigate("/");
  };

  const handleSelected = (id) => {
    let selected = users.filter((user) => user._id === id)[0];
    setSelectedPerson(selected);
  };

  const filteredPeople =
    query === ""
      ? []
      : users.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    const ac = new AbortController();

    const loadUsers = async () => {
      // setLoaded(false);

      try {
        await getUsers({}, ac.signal);
      } catch (error) {
        // setLoaded(true);
        console.dir(error.message);
      }
    };

    loadUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-40">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                {({ activeOption }) => {
                  return (
                    <>
                      <div className="relative">
                        <MagnifyingGlassIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <Combobox.Input className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." onChange={(event) => setQuery(event.target.value)} />
                      </div>

                      {(query === "" || users.length > 0) && (
                        <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                          <div className={classNames("max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4", activeOption && "sm:h-96")}>
                            {query === "" && <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">Recent searches</h2>}
                            <div className="-mx-2 text-sm text-gray-700">
                              {(query === "" ? users : filteredPeople).map((person) => (
                                <Combobox.Option as="div" key={person._id} value={person} onClick={() => handleSelected(person._id)} className={({ active }) => classNames("border flex cursor-pointer select-none items-center rounded-md p-2", active && "bg-gray-50 text-gray-900", person._id === selectedPerson._id ? "border-highlightColor/50" : "border-transparent")}>
                                  {({ active }) => (
                                    <>
                                      <div className="rounded-full hidden sm:block h-6 w-6 flex-shrink-0 overflow-hidden">{person?.image ? <img className="object-cover h-full w-full" src={baseUrl + person?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} /> : <img className="object-cover h-full w-full" src={noProfile} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />}</div>
                                      <span className="ml-3 flex-auto truncate">{person?.name}</span>
                                      {(active || selectedPerson._id === person._id) && <ChevronRightIcon className="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </div>
                          </div>

                          {(activeOption || selectedPerson?.name) && (
                            <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                              <div className="flex-none p-6 text-center">
                                <div className="mx-auto rounded-full hidden sm:block h-16 w-16 flex-shrink-0 overflow-hidden">{activeOption?.image || selectedPerson?.image ? <img className="object-cover h-full w-full" src={baseUrl + activeOption?.image || selectedPerson?.image} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} /> : <img className="object-cover h-full w-full" src={noProfile} alt="profile" onError={(e) => (e.target.src = `${noProfile}`)} />}</div>
                                <h2 className="mt-3 font-semibold text-gray-900">{activeOption?.name || selectedPerson?.name}</h2>
                                <p className="text-sm leading-6 text-gray-500">{activeOption?.username || selectedPerson?.username}</p>
                                <p className="text-sm leading-6 text-gray-500">{activeOption?.email || selectedPerson?.email}</p>
                              </div>
                              <div className="flex flex-auto flex-col justify-between p-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setPreviewUserId(selectedPerson._id || activeOption._id);
                                    setOpen(false);
                                  }}
                                  className="mt-6 w-full rounded-md border border-transparent bg-highlightColor py-2 px-4 text-sm font-medium text-white shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2"
                                >
                                  Update view
                                </button>
                              </div>
                            </div>
                          )}
                        </Combobox.Options>
                      )}

                      {query !== "" && filteredPeople.length === 0 && (
                        <div className="py-14 px-6 text-center text-sm sm:px-14">
                          <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                          <p className="mt-4 font-semibold text-gray-900">No people found</p>
                          <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                        </div>
                      )}
                    </>
                  );
                }}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: Object.values(state.users),
  };
};

export default connect(mapStateToProps, {
  getUsers,
  getUser,
})(RolePreview);
