import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Input";
import Button from "src/components/Shared/Button";
import Toggle from "../Shared/Toggle";
import TextArea from "../Shared/Forms/TextArea";

const ActivitySettings = ({ site, manageSiteSettings }) => {
  const [activityWarningEnable, setActivityWarningEnable] = useState(site?.activity_warning_enable || false);
  const [activityWarningView, setActivityWarningView] = useState(site?.activity_warning_view || "");
  const [activityWarningMessage, setActivityWarningMessage] = useState(site?.activity_warning_message || "");
  const [activityLockoutEnable, setActivityLockoutEnable] = useState(site?.activity_lockout_enable || false);
  const [activityLockoutView, setActivityLockoutView] = useState(site?.activity_lockout_view || "");
  const [activityLockoutMinute, setActivityLockoutMinute] = useState(site?.activity_lockout_minute || "");
  const [activityLockoutMessage, setActivityLockoutMessage] = useState(site?.activity_lockout_message || "");

  const setPageData = (site) => {
    if (site?._id) {
      setActivityWarningEnable(site.activity_warning_enable || false);
      setActivityWarningView(site.activity_warning_view || "");
      setActivityWarningMessage(site.activity_warning_message || "");
      setActivityLockoutEnable(site.activity_lockout_enable || false);
      setActivityLockoutView(site.activity_lockout_view || "");
      setActivityLockoutMinute(site.activity_lockout_minute || "");
      setActivityLockoutMessage(site.activity_lockout_message || "");
    }
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        activity_warning_enable: activityWarningEnable,
        activity_warning_view: activityWarningView,
        activity_warning_message: activityWarningMessage,
        activity_lockout_enable: activityLockoutEnable,
        activity_lockout_view: activityLockoutView,
        activity_lockout_minute: activityLockoutMinute,
        activity_lockout_message: activityLockoutMessage,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md sm:px-10 px-4 py-3 sm:py-8 rounded-md flex flex-wrap justify-between w-full">
          <div className="mt-3 mb-4 flex w-full items-center justify-between">
            <h3 className="pb-1 text-xl font-semibold text-gray-500">Anti-Automation / Abuse Prevention</h3>
          </div>
          <div className="relative w-full mb-5 space-y-20">
            <div className="relative w-full mt-6">
              <div className="font-medium text-gray-500 mb-4 flex gap-x-5">
                Initial warning
                <Toggle checked={activityWarningEnable} onChange={setActivityWarningEnable} />
              </div>
              <div className="flex px-8 flex-col w-full gap-y-3 gap-x-5 justify-center-center">
                <div className="w-[150px]">
                  <Input name="views" label="Max. Views/Minute" value={activityWarningView} onChange={(e) => setActivityWarningView(e.target.value)} />
                </div>
                <div className="w-full max-w-xl">
                  <TextArea name="message" label="Message" value={activityWarningMessage} onChange={(e) => setActivityWarningMessage(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="relative w-full mt-6">
              <div className="font-medium text-gray-500 mb-4 flex gap-x-5">
                Temporary lockout
                <Toggle checked={activityLockoutEnable} onChange={setActivityLockoutEnable} />
              </div>
              <div className="flex px-8 flex-col w-full gap-y-3 gap-x-5 justify-center-center">
                <div className="flex gap-x-4">
                  <div className="w-[150px]">
                    <Input name="views" label="Max. Views/Minute" value={activityLockoutView} onChange={(e) => setActivityLockoutView(e.target.value)} />
                  </div>
                  <div className="w-[150px]">
                    <Input name="locked_min" label="Locked out minutes" value={activityLockoutMinute} onChange={(e) => setActivityLockoutMinute(e.target.value)} />
                  </div>
                </div>
                <div className="w-full max-w-xl">
                  <TextArea name="message" label="Message" value={activityLockoutMessage} onChange={(e) => setActivityLockoutMessage(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-4">
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ActivitySettings);
