import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleDomoData, manageSiteSettings } from "src/actions/site";
// import { apiUrl } from "src/config/host";
import { ArrowDownTrayIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Button";
import FrontLabeledInput from "src/components/Shared/Forms/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Input";
import RadioButtons from "src/components/Shared/Forms/RadioButtons";
import { filePath } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import QuickMenu from "../Shared/QuickMenu";
import DatasetModal from "./DatasetModal";

const SiteCredentials = ({ site, manageSiteSettings, creds }) => {
  // const navigate = useNavigate();
  const [siteName, setSiteName] = useState(site?.site_name || "");
  const [siteEmail, setSiteEmail] = useState(site?.site_email || "");
  const [siteDomain, setSiteDomain] = useState(site?.site_domain || "");
  const [clientId, setClientId] = useState(site?.dataset_client_id || "");
  const [clientSecret, setClientSecret] = useState(site?.dataset_client_secret || "");
  const [datasetId, setDatasetId] = useState(site?.dataset_id || "");
  const [token, setToken] = useState(site?.cron_token || "");
  const [passwordState, setPasswordState] = useState(site?.password_state || false);
  const [smtpHost, setSmtpHost] = useState(site?.smtp_host || "");
  const [smtpUser, setSmtpUser] = useState(site?.smtp_user || "");
  const [smtpPassword, setSmtpPassword] = useState(site?.smtp_password || "");
  const [smtpPort, setSmtpPort] = useState(site?.smtp_port || "");
  const [smtpSecure, setSmtpSecure] = useState(site?.smtp_secure || true);
  const [portalDatasetId, setPortalDatasetId] = useState(site?.portal_dataset_id || "");
  const [domoLoginUrl, setDomoLoginUrl] = useState(site?.domo_login_url || "");
  const [ssoButtonText, setSsoButtonText] = useState(site?.sso_button_text || "");
  const [ssoAdminRedirect, setSsoAdminRedirect] = useState(site?.sso_admin_redirect || false);
  const [apiState, setApiState] = useState(site?.api_state || "PORTAL_CREATED");
  const [isSync, setSync] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [refresh, setRrefresh] = useState(false);

  const setPageData = (site) => {
    if (site?._id) {
      setSiteName(site.site_name);
      setSiteEmail(site.site_email);
      setSiteDomain(site.site_domain);
      setApiState(site.api_state || "");
      setClientId(site.dataset_client_id);
      setClientSecret(site.dataset_client_secret);
      setDatasetId(site.dataset_id);
      setToken(site.cron_token);
      setPasswordState(site.password_state || false);
      setSmtpUser(site.smtp_user || "");
      setSmtpHost(site.smtp_host || "");
      setSmtpPassword(site.smtp_password || "");
      setSmtpPort(site.smtp_port || "");
      setSmtpSecure(site.smtp_secure || true);
      setPortalDatasetId(site.portal_dataset_id || "");
      setDomoLoginUrl(site.domo_login_url || "");
      setSsoButtonText(site.sso_button_text || "");
      setSsoAdminRedirect(site.sso_admin_redirect || true);
    }
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, refresh]);

  const onClear = () => {
    setPageData();
  };

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        site_name: siteName,
        site_email: siteEmail,
        site_domain: siteDomain,
        api_state: apiState,
        dataset_client_id: clientId,
        dataset_client_secret: clientSecret,
        dataset_id: datasetId,
        cron_token: token,
        password_state: passwordState,
        smtp_host: smtpHost,
        smtp_user: smtpUser,
        smtp_password: smtpPassword,
        smtp_port: smtpPort,
        domo_login_url: domoLoginUrl,
        sso_button_text: ssoButtonText,
        portal_dataset_id: portalDatasetId,
        smtp_secure: smtpSecure,
        sso_admin_redirect: ssoAdminRedirect,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const randomHashString = (length) => {
    let result = "";
    const characters = "**************************************************************";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const updateDomo = async (requestType = "pull") => {
    try {
      setSync(true);
      let message = await handleDomoData(requestType);
      toast.success(message);
      setSync(false);
    } catch (error) {
      console.dir(error);
      toast.error(error.message);
      setSync(false);
    }
  };

  return (
    <div className="grid space-y-8">
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-wrap justify-between w-full">
        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2">Site Data</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* Site name */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="site-name"
              label="Name"
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
            />
          </div>

          {/* Contact email */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="contact-email"
              label="Contact email"
              value={siteEmail}
              onChange={(e) => setSiteEmail(e.target.value)}
            />
          </div>

          {/* Site domain */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="site-domain"
              label="User domain"
              value={siteDomain}
              onChange={(e) => setSiteDomain(e.target.value)}
            />
          </div>
        </div>

        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2 mt-5">SSO Details</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* SSO Button Text */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="sso-button-text"
              label="Button Text"
              value={ssoButtonText}
              onChange={(e) => setSsoButtonText(e.target.value)}
            />
          </div>
          <div className="sm:w-1/2 w-full px-2">
            <div className="relative flex flex-col pl-3 pt-3.5">
              <div className="flex justify-between text-sm font-medium text-gray-700 mb-1">URL Redirect</div>
              <div className="pt-1 md:pt-0 items-center flex">
                <input
                  checked={ssoAdminRedirect}
                  onChange={(e) => setSsoAdminRedirect(e.target.checked)}
                  aria-describedby="notifications-description"
                  name="notifications"
                  type="checkbox"
                  className="h-4 w-4 text-highlightColor border-gray-300 rounded"
                />
                <p className="ml-3 text-sm text-gray-500">Redirect admin portal to SSO login</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-gray-500 text-lg font-medium w-full px-2 mb-2 mt-5">SMTP</div>
        <div className="flex flex-wrap w-full gap-y-6">
          {/* SMTP USER */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="smtp-user"
              label="User"
              value={smtpUser}
              onChange={(e) => setSmtpUser(e.target.value)}
            />
          </div>

          {/* SMTP PASSWORD */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="smtp-password"
              label="Password"
              value={randomHashString(smtpPassword.length)}
              onChange={(e) => setSmtpPassword(e.target.value)}
              onFocus={(e) => {
                if (e.target.value.includes("*")) {
                  e.target.value = "";
                }
              }}
            />
          </div>

          {/* SMTP HOST */}
          <div className="sm:w-1/2 w-full px-2">
            <Input
              name="smtp-host"
              label="Host"
              value={smtpHost}
              onChange={(e) => setSmtpHost(e.target.value)}
            />
          </div>
          {/* SMTP port */}
          <div className="relative sm:w-1/2 w-full px-2">
            {/* SMTP HOST */}
            <div className="absolute z-30 flex items-center gap-x-2 ml-2 top-[2px] right-4 text-gray-700 opacity-70 text-xs cursor-pointer">
              <input
                className="rounded-sm text-gray-400 focus:ring-highlightColor cursor-pointer"
                type="checkbox"
                checked={smtpSecure}
                onChange={(e) => setSmtpSecure(e.target.checked)}
              />
              <label className="cursor-pointer">STMP Secure</label>
            </div>

            <div className="w-full">
              <Input
                label="SMTP Port"
                value={smtpPort}
                onChange={(e) => setSmtpPort(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md px-6 py-8 rounded-md flex flex-col justify-between w-full gap-y-6 lg:gap-x-12">
        <div className="px-4 w-full mb-2">
          <label className="text-base font-medium text-gray-900">Import Users from Dataset</label>
          <p className="text-sm leading-5 text-gray-500">Manually created users will no longer be accessible.</p>
          <div className="w-[300px] md:min-w-[500px]">
            <RadioButtons
              items={[
                {
                  id: "PORTAL_CREATED",
                  name: "Create users in portal (default)",
                  checked: "PORTAL_CREATED" === apiState,
                  onChange: () => setApiState("PORTAL_CREATED"),
                },
                {
                  id: "DOMO_DATASET",
                  name: "Use Domo dataset",
                  checked: "DOMO_DATASET" === apiState,
                  onChange: () => setApiState("DOMO_DATASET"),
                },
              ]}
            />
          </div>
        </div>

        <div className="relative rounded-md w-full">
          <div className={classNames("h-full rounded w-full absolute flex items-center justify-center bg-gray-100 opacity-80 z-10", apiState !== "DOMO_DATASET" ? "absolute" : "hidden")}>
            <div className="w-full flex justify-center rotate-[-10deg] text-4xl md:text-4xl lg:text-5xl xl:text-7xl font-extrabold text-gray-300 text-center select-none">For Domo Dataset Use</div>
          </div>
          <div className="px-4 w-full flex flex-col gap-y-6 py-6">
            <div className="grid space-y-2 text-sm lg:w-2/3">
              <FrontLabeledInput
                label="Client ID"
                type="password"
                disabled={apiState !== "DOMO_DATASET"}
                value={randomHashString(clientId?.length)}
                onChange={(e) => setClientId(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <FrontLabeledInput
                label="Client Secret"
                type="password"
                disabled={apiState !== "DOMO_DATASET"}
                value={randomHashString(clientSecret?.length)}
                onChange={(e) => setClientSecret(e.target.value)}
                onFocus={(e) => {
                  if (e.target.value.includes("*")) {
                    e.target.value = "";
                  }
                }}
              />
              <div className="w-full flex relative pb-10">
                <FrontLabeledInput
                  label="Dataset ID"
                  type="text"
                  disabled={apiState !== "DOMO_DATASET"}
                  value={datasetId}
                  onChange={(e) => setDatasetId(e.target.value)}
                />
                <a
                  href={filePath + "/upload-users.csv"}
                  className="absolute top-12 left-0 font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto">
                  <div className="flex items-center justif-center gap-x-2 text-sm">
                    <ArrowDownTrayIcon className="ml-2 w-5 h-5" /> CSV Format
                  </div>
                </a>
              </div>
            </div>
            <div className="flex items-center w-full">
              <div className="w-full sm:w-1/2">
                <Input
                  name="domo-login-url"
                  label="Domo Login URL"
                  value={domoLoginUrl}
                  onChange={(e) => setDomoLoginUrl(e.target.value)}
                />
              </div>
              <a
                href={domoLoginUrl + "/datasources/" + datasetId + "/details/data/table"}
                className="mt-6 flex items-center gap-x-2 pr-6 ml-4 text-regular font-medium text-gray-500 hover:text-highlightColor hover:underline"
                target="_blank"
                rel="noreferrer">
                <p className="hidden sm:block">Link to domo dataset</p>
                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
              </a>
            </div>
            <div className="max-w-[260px]">
              <RadioButtons
                disabled={apiState !== "DOMO_DATASET"}
                heading="Overwrite user passwords"
                subheading="Does your dataset contain your user's passwords?"
                items={[
                  {
                    id: "yes",
                    name: "Yes",
                    checked: passwordState === true,
                    onChange: () => setPasswordState(true),
                  },
                  {
                    id: "no",
                    name: "No",
                    checked: passwordState === false,
                    onChange: () => setPasswordState(false),
                  },
                ]}
              />
            </div>
            <div className="flex flex-wrap justify-between w-full gap-y-6">
              <div className="flex w-full mt-5 gap-x-4 justify-end items-center">
                <div className="relative">
                  <Button
                    disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET"}
                    styleType="gray"
                    onClick={() => updateDomo("pull")}>
                    {!isSync && <i className="far fa-repeat mr-2"></i>}{" "}
                    {isSync && (
                      <div
                        className="spinner-border inline-block h-5 w-5 animate-spin rounded-full border-[2px]"
                        role="status"
                      />
                    )}{" "}
                    Sync with DOMO
                  </Button>
                  <div className="flex justify-end absolute top-1 -left-7">
                    <QuickMenu
                      disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET"}
                      items={[
                        {
                          name: "Upload users to dataset",
                          onClick: () => updateDomo("push"),
                          color: "text-red-600",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end gap-x-4 pb-40">
        <Button
          styleType="gray"
          onClick={onClear}>
          Undo changes
        </Button>
        <Button onClick={onSubmit}>Update</Button>
      </div>
      <DatasetModal
        site={site}
        creds={creds}
        setRrefresh={setRrefresh}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        submitText="Save"
        cancelText="Cancel"
        title="Download Domo Dataset"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    creds: state.creds,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteCredentials);
